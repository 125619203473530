<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Name')" v-model="news.name">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('State') }}</label>
                    <div class="control">
                        <input type="checkbox" v-model="isActive" @click="toggleCheckboxes">
                        {{ i18n(isActive ? 'Active' : 'Inactive') }}
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Select Program') }}</label>
                    <vue-select :source="route('programs.options')"
                                v-model="news.program"
                                :http="http"
                                track-by="name"
                                :placeholder="i18n('Pick an option')"/>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Property') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('UserProperty')" v-model="news.userProperty">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Segmentation') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Segmentation')" v-model="news.segmentation">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Position') }}</label>
                    <div class="control">
                        <input class="input" type="number"
                               :placeholder="i18n('Position')" v-model="news.position">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Description') }}</label>
                    <div class="control">
                    <textarea class="textarea" :placeholder="i18n('Description')"
                              v-model="news.description" maxlength="800"/>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Notify By Mail') }}</label>
                    <div class="control">
                        <label class="radioBtt">
                            <input type="radio" v-model="news.notifyByMail"
                                   name="notifyOption" value="Si">
                            {{ i18n('Yes') }}
                        </label>
                        <label>
                            <input type="radio" v-model="news.notifyByMail"
                                   name="notifyOption" value="No">
                            {{ i18n('Not') }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Comments') }}</label>
                    <div class="control">
                        <label class="radioBtt">
                            <input type="radio" v-model="news.comments"
                                   name="comments" value="Si">
                            {{ i18n('Yes') }}
                        </label>
                        <label>
                            <input type="radio" v-model="news.comments"
                                   name="comments" value="No">
                            {{ i18n('Not') }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Choose an File') }}</label>
                    <div class="control">
                        <label class="file-label">
                            <input class="file-input" type="file"
                                   accept=".pdf, .jpg, .png, .webp" @change="handleFileUpload">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ i18n('Choose an File') }}...
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || i18n('No file chosen') }}
                            </span>
                        </label>
                        <p class="letterFileSize">
                            {{ i18n('Maximum file size') }}: 18 MB.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchSave">
            <button class="button butt-save">
                {{ i18n('Save') }}
                <fa class="i-icon" icon="check"/>
            </button>
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { VueSelect } from '@enso-ui/select/bulma';

export default {
    name: 'Create',

    components: {
        Fa,
        VueSelect,
    },

    inject: ['http', 'i18n', 'errorHandler', 'route', 'errors'],

    data: () => ({
        news: {},
        isActive: false,
        programs: {},
        fileName: '',
    }),

    methods: {
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.news.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },

        fetchSave() {
            const formData = new FormData();
            formData.append('file', this.news.file);
            formData.append('name', this.news.name);
            formData.append('state', this.isActive ? 'Active' : 'Inactive');
            formData.append('program', this.news.program);
            formData.append('userProperty', this.news.userProperty);
            formData.append('segmentation', this.news.segmentation);
            formData.append('position', this.news.position);
            formData.append('description', this.news.description);
            formData.append('notifyByMail', this.news.notifyByMail);
            formData.append('comments', this.news.comments);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('news.store'), formData,
                { headers })
                .then(() => {
                    this.$router.push('/news');
                }).catch(this.errorHandler);
        },

        toggleCheckboxes() {
            this.isActive = !this.news.state === 'Active';
            this.news.state = !this.news.state === 'Active' ? 'Active' : 'Inactive';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.butt-save{
    background-color: $green;
    border-color: transparent;
    color: $secondary !important;
}

.button-alignment{
    padding-top: 33px;
}

.styleInput{
    background-color: transparent;
    border-color: transparent;
}

.letterFileSize{
    font-size: small;
    color: $greyToneEleven;
}

.radioBtt{
    padding-right: 50px;
}

</style>
